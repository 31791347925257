import OasisMapping from "../../../oasisMapping.json";

export default function oasisInitialState() {
  const state: any = {
    objects: [],
    summary: {},
    participant: {},
    publication: [
      {
        publicationType: "Journal Article",
      },
    ],
    research: {
      researchTitle: "",
      researchText: "",
      whatResearchersDidText: "",
      whatResearchersFoundText: "",
      thingsToConsiderText: "",
    },
    settings: {},
  };

  OasisMapping.map((item: any) => {
    if (item.partOf === "publication") {
      state[item.partOf][0][item.fieldName] = item.isKeywordSearch ? [] : "";
    } else {
      state[item.partOf][item.fieldName] = item.isKeywordSearch ? [] : "";
    }
    return true;
  });

  return state;
}
