import React from "react";
import { createDropdownList } from "../components/submitElements/steps/createDropdownList";
import getApiData from "../lib/getApiData";
import { getProjectName } from "../lib/functions";
import OasisMapping from "../../oasisMapping.json";

const irisEndpoints = [
  "dd/authors",
  "dd/items/typeOfInstruments",
  "dd/items/researchAreas",
  "dd/items/dataTypes",
  "dd/items/linguisticTargets",
  "dd/languages",
  "dd/items/funders",
  "dd/items/fileTypes",
  "dd/items/participantTypes",
  "dd/items/proficiencies",
  "dd/items/domainOfUse",
  "dd/items/journals",
  "dd/items/publishers",
  "dd/items/rights",
];

const oasisEndpoints = OasisMapping.filter(
  (item: any) => item.ddCategoryKey && item.isKeywordSearch
).map((item: any) => `dd/items/${item.ddCategoryKey}`);

const getDictionaryData = async () => {
  try {
    const projectName = getProjectName(true);

    const endpoints = projectName === "OASIS" ? oasisEndpoints : irisEndpoints;

    const promises: any[] = [];

    endpoints.map(async (endpoint) => promises.push(getApiData({ endpoint })));

    const dictionaryData: any = [];

    await Promise.all(promises).then((data: any) => {
      return data.map(async (response: any, i: number) => {
        const endpoint = endpoints[i].split("/").at(-1);
        const dropdownList = await createDropdownList(response.data);
        dictionaryData[endpoint] = dropdownList;
        return response.data;
      });
    });

    return dictionaryData;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};

export default getDictionaryData;
