import CryptoJS from "crypto-js";

export default {
  encryptObject(object: any, password: string): string {
    return CryptoJS.AES.encrypt(JSON.stringify(object), password).toString();
  },

  decryptObject(encrypted: string, password: string): any {
    const bytes = CryptoJS.AES.decrypt(encrypted, password);
    const json = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(json);
  },
};
