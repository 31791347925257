import React, { useReducer, createContext } from "react";
import submissionReducer from "../reducers/SubmissionReducer";
import { getProjectName } from "../lib/functions";

import { irisInitialState } from "../types/iris/irisInitialState";
import oasisInitialState from "../types/oasis/oasisInitialState";

export const SubmissionState = createContext(null);
export const SubmissionDispatch = createContext(null);

function SubmissionContext({ children }) {
  const projectName = getProjectName(true);
  let useInitialState = {};

  if (projectName === "IRIS") {
    useInitialState = irisInitialState;
  }

  if (projectName === "OASIS") {
    useInitialState = oasisInitialState();
  }

  const [state, dispatch] = useReducer(submissionReducer, useInitialState);

  return (
    <SubmissionState.Provider value={state}>
      <SubmissionDispatch.Provider value={dispatch}>
        {children}
      </SubmissionDispatch.Provider>
    </SubmissionState.Provider>
  );
}

export default SubmissionContext;
