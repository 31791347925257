/* eslint-disable import/prefer-default-export */

import { IIrisMaterial, IrisElementTypes } from "./IIrisMaterial";

export const irisInitialState: IIrisMaterial = {
  objectId: "",
  objects: [],
  instrument: {
    creator: [],
    materialType: [],
    primaryMaterialType: {
      type: IrisElementTypes.DATA_DICTIONARY,
      value: "",
    },
    researchArea: [],
    typeOfFile: [],
    requiredSoftware: [],
    dataType: [],
    linguisticFeature: [],
    language: [],
    sourceOfFunding: [],
    title: [],
    reliability: [],
    notes: "",
  },
  participant: {
    type: [],
    firstLanguage: [],
    languageBeingLearned: [],
    genderOfLearners: [],
    proficiencyOfLearners: [],
    typeOfInstruction: [],
    amountOfInstruction: [],
    lengthOfResidence: [],
    amountOfPriorInstruction: [],
    timeSpent: [],
    domainOfUse: [],
    ageOfLearner: [],
    ageOfArrival: [],
    ageOfStudents: [],
    yearsOfExperience: [],
  },
  publication: [],
  acknowledgement: [],
  settings: {
    rights: "",
    feedback: "",
    notifyDownloads: "",
    email: "",
    comments: "",
    licenceAgreement: "",
  },
};
