exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-dd-manager-tsx": () => import("./../../../src/pages/admin/dd/manager.tsx" /* webpackChunkName: "component---src-pages-admin-dd-manager-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-details-[id]-pdf-tsx": () => import("./../../../src/pages/details/[id]/pdf.tsx" /* webpackChunkName: "component---src-pages-details-[id]-pdf-tsx" */),
  "component---src-pages-details-[id]-tsx": () => import("./../../../src/pages/details/[id].tsx" /* webpackChunkName: "component---src-pages-details-[id]-tsx" */),
  "component---src-pages-formtest-tsx": () => import("./../../../src/pages/formtest.tsx" /* webpackChunkName: "component---src-pages-formtest-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-activate-tsx": () => import("./../../../src/pages/login/activate.tsx" /* webpackChunkName: "component---src-pages-login-activate-tsx" */),
  "component---src-pages-login-resetpassword-tsx": () => import("./../../../src/pages/login/resetpassword.tsx" /* webpackChunkName: "component---src-pages-login-resetpassword-tsx" */),
  "component---src-pages-login-signup-tsx": () => import("./../../../src/pages/login/signup.tsx" /* webpackChunkName: "component---src-pages-login-signup-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-page-tsx": () => import("./../../../src/pages/page.tsx" /* webpackChunkName: "component---src-pages-page-tsx" */),
  "component---src-pages-requestmaterials-tsx": () => import("./../../../src/pages/requestmaterials.tsx" /* webpackChunkName: "component---src-pages-requestmaterials-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-submit-material-id-[step]-tsx": () => import("./../../../src/pages/submit/[materialID]/[step].tsx" /* webpackChunkName: "component---src-pages-submit-material-id-[step]-tsx" */),
  "component---src-pages-submit-new-tsx": () => import("./../../../src/pages/submit/new.tsx" /* webpackChunkName: "component---src-pages-submit-new-tsx" */),
  "component---src-pages-submit-similar-material-id-tsx": () => import("./../../../src/pages/submit/similar/[materialID].tsx" /* webpackChunkName: "component---src-pages-submit-similar-material-id-tsx" */),
  "component---src-pages-support-help-with-copyright-tsx": () => import("./../../../src/pages/support/help-with-copyright.tsx" /* webpackChunkName: "component---src-pages-support-help-with-copyright-tsx" */),
  "component---src-pages-support-help-with-download-tsx": () => import("./../../../src/pages/support/help-with-download.tsx" /* webpackChunkName: "component---src-pages-support-help-with-download-tsx" */),
  "component---src-pages-support-help-with-login-tsx": () => import("./../../../src/pages/support/help-with-login.tsx" /* webpackChunkName: "component---src-pages-support-help-with-login-tsx" */),
  "component---src-pages-support-help-with-searching-tsx": () => import("./../../../src/pages/support/help-with-searching.tsx" /* webpackChunkName: "component---src-pages-support-help-with-searching-tsx" */),
  "component---src-pages-support-help-with-submission-tsx": () => import("./../../../src/pages/support/help-with-submission.tsx" /* webpackChunkName: "component---src-pages-support-help-with-submission-tsx" */),
  "component---src-pages-user-overview-tsx": () => import("./../../../src/pages/user/overview.tsx" /* webpackChunkName: "component---src-pages-user-overview-tsx" */)
}

