import { navigate } from "gatsby";
import _ from "lodash";
import getApiData from "./getApiData";
import Encryption from "../helpers/encryption";

interface SendEventToGAProps {
  type: string;
  parameters: object;
}

export const sendEventToGA = (props: SendEventToGAProps) => {
  const { type, parameters } = props;

  if (process.env.NODE_ENV === "development") {
    console.log(`Submit click event to GA`);
    console.log("🚀 ~ sendEventToGA ~ type:", type);
    console.log("🚀 ~ sendEventToGA ~ parameters:", parameters);
  }

  if (process.env.NODE_ENV === "production") {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== "undefined" && window.gtag("event", type, parameters);
  }
};

export const parseLocation = (props: any) => {
  if (props && props.location && props.location.search) {
    return returnPairs({ paramsArray: props.location.search });
  }
  return "";
};

interface returnPairsProps {
  paramsArray: any;
}

export const returnPairs = (props: returnPairsProps) => {
  return _.fromPairs(
    // props.location.search
    props.paramsArray
      .replace("?", "")
      .split("&")
      .map(_.partial(_.split, _, "=", 2))
  );
};

export const validateToken = async (token: string, redirect = true) => {
  try {
    if (token) {
      const userHasValidToken = await getApiData({
        endpoint: "security/validateToken",
        method: "post",
        headers: {
          "X-Amz-Security-Token": token,
        },
      });

      if (redirect) {
        if (userHasValidToken?.status !== 200) {
          // Redirect the user to the login page when token is not valid
          // Navigate user to logout page
          navigate("/logout");
        } else {
          return true;
        }
      } else {
        return userHasValidToken?.status === 200;
      }
    }
  } catch (error) {
    console.log("🚀 ~ validateToken ~ error:", error);
    return false;
  }
  return false;
};

/**
 * Flatten an Dictionary Data array
 * @param data
 * @returns flat array
 */
export const flattenDD = (data: type) => {
  let newArray: any[] = [];
  if (data.length > 0) {
    data.map((item: any) => {
      const newItem = { ...item };
      delete newItem.type;
      newArray.push(newItem);
      if (item.type) {
        const subItems = flattenDD(item.type);
        newArray = concat(newArray, subItems);
      }
    });
  }
  return newArray;
};

/**
 * Get user rights object
 */

export const userRightsDecoded = (
  objectId: string,
  userRightsString: string
) => {
  const password = `${objectId}-dti-2023`;
  return Encryption.decryptObject(userRightsString, password);
};

/**
 * Return the correct `project name` so different components and styles can be used
 */
export const getProjectName = (ReturnCapitals = false) => {
  let projectName = "iris";

  try {
    if (typeof window !== "undefined") {
      // Client-side-only code
      const domainName = window.location.hostname;

      if (domainName.includes("iris")) {
        projectName = "iris";
      }

      if (domainName.includes("oasis")) {
        projectName = "oasis";
      }
    }
  } catch (error) {
    console.log("🚀 ~ getProjectName ~ error:", error);
  }

  // To set the `projectName` manually uncommand these:

  if (process.env.GATSBY_SITE === "OASIS") {
    projectName = "oasis";
  }

  if (process.env.GATSBY_SITE === "IRIS") {
    projectName = "iris";
  }

  // projectName = "oasis";
  // projectName = "iris";

  return ReturnCapitals ? projectName.toUpperCase() : projectName;
};

export const extractS3Key = (uploadURL: string): string => {
  // Match the part of the URL after the bucket name
  const match = uploadURL.match(/https:\/\/[^/]+\/(.+)/);
  // Return the matched group or an empty string if no match
  return match ? match[1] : "";
};

/**
 * Simple function to turn a string into a valid CSS class name
 * @param str
 * @returns str
 */
export function stringToClassName(str: string) {
  return str
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "")
    .toLowerCase();
}

/**
 * Function to check if DD type is New
 * @param obj {}
 * @returns boolean
 */
export function isNew(obj: any) {
  if (obj && obj.type && obj.type.toLowerCase() === "new") {
    return true;
  }
  return false;
}

export function formatDate(dateString: string) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Format and return the date as dd-mm-yyyy
  return `${day}-${month}-${year}`;
}

export const ensureEndsWithPunctuation = (str: string) => {
  if (!str) return ""; // Handle undefined or null
  const punctuation = [".", "?", "!"];
  return punctuation.includes(str.slice(-1)) ? str : `${str}.`;
};
